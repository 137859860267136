<template>
  <div id="demo">
    <div class="content_box">
      <div class="inner_max">
        <div class="title">
          <i class="icon el-icon-circle-check text-light-green mb-3"></i>
          <div class="text big">
            <b>{{ $t('register.demo.congrad') }}</b>
          </div>
          <div class="text small">{{ $t('register.demo.title', { platform: userInfo.platform }) }}</div>
        </div>
        <div class="top">
          <div class="left">
            <h3>{{ $t('register.demo.title2') }}</h3>
            <ul>
              <li>
                {{ $t('register.demo.li.login') }}
                <b class="info">{{ userInfo.accNum }}</b>
              </li>
              <!-- <li v-if="userInfo.Password">
                {{ $t('register.demo.li.password') }}
                <b class="info">{{ userInfo.Password }}</b>
              </li> -->
              <li>
                {{ $t('register.demo.li.srv') }}
                <b class="info">{{ userInfo.server }}</b>
              </li>
              <li class="mb-3">
                {{ $t('register.demo.li.expDate') }}
                <b class="info">{{ userInfo.expiryDate | date }}</b>
              </li>
            </ul>
            <span>{{ $t('register.demo.emailSent') }}</span>
          </div>
          <div class="right">
            <h3>{{ $t('register.demo.dl', { platform: userInfo.platform }) }}</h3>
            <ul v-if="urls[`mt${userInfo.platform}`]" class="mt4_ul">
              <li :style="{ width: `${liWidth}%` }">
                <a
                  :href="urls[`mt${userInfo.platform}`][`METATRADER${userInfo.platform}_windows`]"
                  data-testid="_windows"
                >
                  <div class="picture windows"></div>
                  <span>{{ $t('downloads.windows') }}</span>
                </a>
              </li>
              <li
                v-if="urls[`mt${userInfo.platform}`][`METATRADER${userInfo.platform}_iOS`]"
                data-testid="_iOS"
                :style="{ width: `${liWidth}%` }"
              >
                <a :href="urls[`mt${userInfo.platform}`][`METATRADER${userInfo.platform}_iOS`]" data-testid="_Mac">
                  <div class="picture mac"></div>
                  <span>{{ $t('downloads.mac') }}</span>
                </a>
              </li>
              <li :style="{ width: `${liWidth}%` }">
                <a
                  :href="urls[`mt${userInfo.platform}`][`METATRADER${userInfo.platform}_iPhone`]"
                  data-testid="_iPhone"
                >
                  <div class="picture iphone"></div>
                  <span>{{ $t('downloads.ip') }}</span>
                </a>
              </li>
              <li :style="{ width: `${liWidth}%` }">
                <a
                  :href="urls[`mt${userInfo.platform}`][`METATRADER${userInfo.platform}_Android`]"
                  data-testid="_Android"
                >
                  <div class="picture android"></div>
                  <span>{{ $t('downloads.android') }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="middle">
          <div class="left">
            <h3>{{ $t('register.demo.proTraderPlatform.title') }}</h3>
            <ul>
              <li class="intro">
                {{ $t('register.demo.proTraderPlatform.content', { platform: userInfo.platform }) }}
              </li>
              <li class="mt-2">
                {{ $t('register.demo.li.login') }}
                <b class="info">{{ userInfo.accNum }}</b>
              </li>
              <li class="pro_trader_img">
                <img src="@/assets/images/computer.png" alt />
              </li>
              <li class="btn_wrapper">
                <el-button class="bg-primary" data-testid="login" @click="goToTradingView">
                  {{ $t('common.button.login') }}
                </el-button>
                <div class="powered_by">
                  <p>{{ $t('register.demo.poweredBy') }}</p>
                  <img src="@/assets/images/tradingView.png" alt />
                </div>
              </li>
            </ul>
          </div>
          <div class="right">
            <h3>{{ $t('register.demo.appTrader.title') }}</h3>
            <ul>
              <li class="intro">
                {{ $t('register.demo.appTrader.content') }}
              </li>
              <li class="link_wrapper mt-3">
                <div class="ios">
                  <img class="mb-3" src="@/assets/images/download/downloads_apptrader_iphone.png" alt />
                  <a :href="urls['appTrader']['APPTRADER_iOS']" target="_blank" data-testid="appStore">
                    <img src="@/assets/images/download/app_store_download.png" alt />
                  </a>
                </div>
                <div class="android">
                  <img class="mb-4" src="@/assets/images/download/qrcode_play_store.png" alt />
                  <a :href="urls['appTrader']['APPTRADER_Android']" target="_blank" data-testid="playStore">
                    <img src="@/assets/images/download/play_store_download.png" alt />
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="bottom">
          <h3>{{ $t('register.demo.improveQ') }}</h3>
          <div class="text">{{ $t('register.demo.improveWay') }}</div>
          <div class="boxes">
            <div class="pro_trader" @click="clickLink('MARKET_BUZZ')">
              <div class="title">
                {{ $t('register.demo.proTrade') }}
              </div>
              <img src="@/assets/images/register/demo/pro_trader_tools.png" alt />
            </div>
            <div class="cashback" @click="clickLink('cashback')">
              <div class="title">
                {{ $t('register.demo.cashback') }}
              </div>
              <img src="@/assets/images/register/demo/cashback.png" alt />
            </div>
            <div class="masters" @click="clickLink('MARKET_MASTERS')">
              <div class="title">
                {{ $t('register.demo.marketMasters') }}
              </div>
              <img src="@/assets/images/register/demo/market_master.png" alt />
            </div>
          </div>
          <div class="btn_box">
            <router-link
              :to="{ name: 'register' }"
              class="el-button text-uppercase bg-magenta"
              data-testid="openLiveAcc"
            >
              {{ $t('register.demo.openLiveAcc') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import demoMixins from '@/mixins/register/demo';
import protraderPlatformMixins from '@/mixins/proTools/protraderPlatform';

export default {
  name: 'demo_default',
  mixins: [demoMixins, protraderPlatformMixins]
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/register/demo.scss';
</style>
