import { apiGet_demo_accs } from '@/resource'

export default {
  data() {
    return {
      userInfo: {
        platform: '4',
      },
      liWidth: 21,
    }
  },
  computed: {
    urls() {
      return this.$platform.getMetaTraderLinks(this.regulator, this.GLOBAL_DOMAIN_WEBSITE)
    },
  },
  mounted() {
    const liCount = document.getElementsByClassName('mt4_ul')[0].getElementsByTagName('li').length
    this.liWidth = 100 / liCount
    apiGet_demo_accs().then(resp => {
      if (resp.data.code == 0 && resp.data.data.length > 0) {
        this.userInfo = { ...resp.data.data[0] }
      }
    })
  },
  methods: {
    clickLink(page) {
      this.$router.push({ name: page })
    },
  },
}
